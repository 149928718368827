<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Solicitud Vehículos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Orion</li>
                  <li class="breadcrumb-item active">Solicitudes</li>
                  <li class="breadcrumb-item active">Vehículos</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <fieldset class="well card">
              <legend class="well-legend text-bold bg-info text-light">
                Competencias para vehículos
                <span
                  class="badge"
                  :class="[
                    form.estado == 1
                      ? 'badge-secondary'
                      : form.estado == 2
                      ? 'badge-success'
                      : form.estado == 3
                      ? 'badge-danger'
                      : form.estado == 4
                      ? 'bg-olive'
                      : form.estado == 5
                      ? 'badge-warning'
                      : form.estado == 6
                      ? 'badge-info'
                      : form.estado == 7
                      ? 'bg-navy'
                      : form.estado == 8
                      ? 'bg-lime'
                      : '',
                  ]"
                  >{{ form.nEstado }}</span
                >
              </legend>
              <div class="row">
                <div class="col-md-9">
                  <div class="row">
                    <div class="form-group col-md-4">
                      <label for="tipo_documento">Placa</label>
                      <input
                        type="text"
                        class="form-control"
                        id="placa"
                        v-model="form.placa"
                        :class="
                          $v.form.placa.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        :disabled="bloquear"
                        @change="datosVehiculo(form.placa)"
                      />
                      <div style="color: red">
                        {{
                          isValidPlaca
                            ? ""
                            : "No ingresar caracteres especiales"
                        }}
                      </div>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="email">Correo</label>
                      <input
                        type="email"
                        class="form-control"
                        id="email"
                        v-model="form.email"
                        :class="
                          $v.form.email.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        :disabled="bloquear"
                      />
                      <div style="color: red">
                        {{ isValidEmail ? "" : "El correo es invalido" }}
                      </div>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="area">Area</label>
                      <v-select
                        class="form-control p-0"
                        v-model="form.area"
                        :class="[
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                          $v.form.area.$invalid ? 'is-invalid' : 'is-valid',
                        ]"
                        placeholder="Area"
                        label="descripcion"
                        :options="listsForms.areas"
                        @input="selectArea()"
                        :disabled="bloquear"
                      >
                      </v-select>
                    </div>
                    <div class="form-group col-md-4">
                      <div class="col-md-12 form-group">
                        <label for="tipo_documento">Sitio</label>
                        <v-select
                          class="form-control p-0"
                          v-model="form.sitio"
                          :class="[
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                            $v.form.sitio.$invalid ? 'is-invalid' : 'is-valid',
                          ]"
                          placeholder="Sitio"
                          label="nombre"
                          :options="listsForms.sitios"
                          @input="selectSitio()"
                          :disabled="bloquear"
                        >
                        </v-select>
                      </div>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="fecha_nacimiento">Operación Vehículo</label>
                      <v-select
                        class="form-control p-0"
                        v-model="form.operacion_vehiculo"
                        :class="[
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                          $v.form.operacion_vehiculo.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                        ]"
                        :disabled="bloquear"
                        placeholder="Operacion Vehiculo"
                        label="descripcion"
                        :options="listsForms.operacion_vehiculos"
                        @input="selectOperacionVh()"
                      >
                      </v-select>
                    </div>
                    <div class="form-group col-md-4">
                      <div class="col-md-12 form-group">
                        <label for="tipo_contrato">Empresa</label>
                        <v-select
                          class="form-control p-0"
                          v-model="form.empresa"
                          :class="[
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                            $v.form.empresa.$invalid
                              ? 'is-invalid'
                              : 'is-valid',
                          ]"
                          :disabled="bloquear"
                          placeholder="empresa"
                          label="descripcion"
                          :options="listsForms.empresas"
                          @input="selectEmpresa()"
                        >
                        </v-select>
                      </div>
                    </div>
                    <div class="form-group col-md-4">
                      <div class="col-md-12 form-group">
                        <label for="tipo_contrato">Activo al que entra</label>
                        <v-select
                          class="form-control p-0"
                          v-model="form.activo"
                          :class="[
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                            $v.form.activo.$invalid ? 'is-invalid' : 'is-valid',
                          ]"
                          :disabled="bloquear"
                          placeholder="activos"
                          label="descripcion"
                          :options="listsForms.activos"
                          @input="selectActivo()"
                        >
                        </v-select>
                      </div>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="tipo_contrato">Fecha Ingreso</label>
                      <input
                        type="date"
                        class="form-control"
                        id="fecha_insc_runt"
                        v-model="form.fecha_ingreso"
                        :min="hoy"
                        @input="validaFechas()"
                        :class="
                          $v.form.fecha_ingreso.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        @change="validaFechaHoy()"
                        :disabled="bloquear"
                      />
                    </div>
                    <div class="form-group col-md-4">
                      <label for="experiecia_cond">Fecha Salida</label>
                      <input
                        type="date"
                        class="form-control"
                        id="fecha_insc_runt"
                        v-model="form.fecha_salida"
                        :min="hoy"
                        @input="validaFechas()"
                        :class="
                          $v.form.fecha_salida.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        :disabled="bloquear"
                      />
                    </div>
                    <div class="form-group col-md-4">
                      <label for="fecha_insc_runt">Marca</label>
                      <v-select
                        class="form-control p-0"
                        v-model="form.marca"
                        :class="[
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                          $v.form.marca.$invalid ? 'is-invalid' : 'is-valid',
                        ]"
                        :disabled="bloquear"
                        placeholder="Marca"
                        label="descripcion"
                        :options="listsForms.marcas"
                        @input="selectMarca()"
                      >
                      </v-select>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="categoria_licencia">Modelo</label>
                      <v-select
                        class="form-control p-0"
                        v-model="form.modelo"
                        :class="[
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                          $v.form.modelo.$invalid ? 'is-invalid' : 'is-valid',
                        ]"
                        :disabled="bloquear"
                        placeholder="Modelo"
                        label="modelo"
                        :options="listsForms.modelos"
                        @input="selectModelo()"
                      >
                      </v-select>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="clase_vehiculo">Clase Vehículo</label>
                      <v-select
                        class="form-control p-0"
                        v-model="form.clase_vehiculo"
                        @input="selectClaseVehiculo(), alert()"
                        :class="[
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                          $v.form.clase_vehiculo.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                        ]"
                        :disabled="bloquear"
                        placeholder="clase_vehiculo"
                        label="descripcion"
                        :options="listsForms.clases_vehiculos"
                      >
                      </v-select>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="clase_vehiculo">Tipo Servicio Placa</label>
                      <select
                        class="form-control"
                        v-model="form.tipo_servicio_placa"
                        :class="
                          $v.form.tipo_servicio_placa.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        :disabled="bloquear"
                        placeholder="Seleccione"
                      >
                        <option value="">Seleccione...</option>
                        <option
                          :value="opt.numeracion"
                          v-for="opt in listsForms.tipo_servicio_placa"
                          :key="opt.id"
                        >
                          {{ opt.descripcion }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="clase_vehiculo">Observaciones</label>
                      <textarea
                        class="form-control"
                        id="fecha_insc_runt"
                        v-model="form.observaciones"
                        :disabled="bloquear"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div class="col-md-2 text-center">
                  <label for="imagen">Foto Vehículo</label>
                  <div
                    class="form-group"
                    v-if="form.link_fotografia == null"
                    :disabled="bloquear"
                  >
                    <input
                      v-show="!miniLogo"
                      style="color: red"
                      type="file"
                      class="form-control-file"
                      accept="image/x-png,image/gif,image/jpeg"
                      @change="obtenerImagen"
                      :disabled="bloquear"
                    />
                    <br v-show="!miniLogo" />
                    <p
                      class="text-center"
                      v-show="!miniLogo"
                      style="color: red"
                    >
                      Fotografía requerida (Max 2MB)
                    </p>
                  </div>
                  <figure class="form-group" v-if="miniLogo">
                    <div class="button-container">
                      <img
                        :src="miniLogo"
                        alt="Logo"
                        width="236"
                        height="125"
                        class="rounded mx-auto d-block"
                        :disabled="bloquear"
                      />
                      <a
                        @click="destroyImage()"
                        id="BotonEliminar"
                        :disabled="bloquear"
                        v-if="miniLogo"
                        href="#"
                        >Eliminar <i class="fas fa-window-close"></i
                      ></a>
                    </div>
                  </figure>
                </div>
              </div>
              <div v-show="form.id">
                <SolicitudVehiculoDocumento
                  v-show="form.id != null"
                  ref="SolicitudVehiculoDocumento"
                />
              </div>
              <div class="text-center">
                <button
                  type="button"
                  class="btn btn-secondary col-md-1"
                  @click="back()"
                >
                  <i class="fas fa-reply"></i><br />Volver
                </button>
                <button
                  type="button"
                  class="btn bg-primary col-md-1"
                  @click="save(1)"
                  v-show="!$v.form.$invalid && miniLogo && !bloquear"
                >
                  <i class="fas fa-paper-plane"></i><br />Guardar
                </button>
                <button
                  v-show="form.id && !bloquear && solicita && form.estado == 1"
                  type="button"
                  class="btn btn-warning col-md-1"
                  v-if="
                    $refs.SolicitudVehiculoDocumento
                      ? $refs.SolicitudVehiculoDocumento.estadoDocs
                      : false
                  "
                  @click="solicitar()"
                >
                  <i class="fas fa-clipboard-check"></i><br />
                  Solicitar
                </button>
              </div>
            </fieldset>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import vSelect from "vue-select";
import {
  required,
  email,
  numeric,
  helpers,
  maxLength,
  minLength,
} from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import SolicitudVehiculoDocumento from "./SolicitudVehiculoDocumento";
const alphaNumeric = helpers.regex("alpha", /^[a-z A-Z 0-9 Ññ]*$/);

export default {
  name: "SolicitudVehiculoForm",
  components: { vSelect, SolicitudVehiculoDocumento, Loading },

  data() {
    return {
      solicita: false,
      estado_anterior: null,
      observaciones_estado: "",
      user_id: null,
      accion: null,
      bloquear: null,
      modalDetalle: [],
      cargando: false,
      miniLogo: null,
      fileFotografia: null,
      fileFotografiaAnterior: null,
      formData: null,
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
      solicitudVh: {},
      vehiculo: {},
      hoy: moment().format("YYYY-MM-DD"),
      formAnterior: {
        vehiculo_id: null,
        placa: null,
        email: null,
        area_id: null,
        sitio_id: null,
        operacion_vehiculo_id: null,
        empresa_id: null,
        activo_id: null,
        fecha_ingreso: null,
        fecha_salida: null,
        marca_id: null,
        modelo_id: null,
        clase_vehiculo_id: null,
        tipo_servicio_placa: null,
      },
      form: {
        id: null,
        vehiculo_id: null,
        placa: null,
        email: null,
        area: {},
        area_id: null,
        sitio: {},
        sitio_id: null,
        operacion_vehiculo: {},
        operacion_vehiculo_id: null,
        empresa: {},
        empresa_id: null,
        activo: {},
        activo_id: null,
        fecha_ingreso: null,
        fecha_salida: null,
        marca: {},
        marca_id: null,
        modelo: {},
        modelo_id: null,
        clase_vehiculo: {},
        clase_vehiculo_id: null,
        observaciones: "",
        fecha: null,
        tipo_servicio_placa: null,
        estado: null,
        Estado: null,
        nEstado: null,
      },
      listsForms: {
        empresas: [],
        activos: [],
        modelos: [],
        tipos_vehiculos: [],
        categorias_licencias: [],
        clases_vehiculos: [],
        operacion_vehiculos: [],
        areas: [],
        marcas: [],
        sitios: [],
        tipo_servicio_placa: [],
      },
    };
  },

  validations: {
    form: {
      placa: {
        required,
        alphaNumeric,
        maxLength: maxLength(8),
        minLength: minLength(5),
      },
      email: {
        required,
        email,
      },
      area: {
        required,
      },
      sitio: {
        required,
      },
      fecha_ingreso: {
        required,
      },
      fecha_salida: {
        required,
      },
      empresa: {
        required,
      },
      activo: {
        required,
      },
      marca: {
        required,
      },
      modelo: {
        required,
      },
      operacion_vehiculo: {
        required,
      },
      clase_vehiculo: {
        required,
      },

      tipo_servicio_placa: {
        required,
      },
    },
  },

  methods: {
    async init() {
      this.accion = this.$route.params.accion;
      await this.getModelos();
      await this.getEmpresas();
      await this.getActivos();
      await this.getOperacionVehiculos();
      await this.getSitios();
      await this.getClasesVehiculos();
      await this.getMarcas();
      await this.getAreas();
      await this.getTipoVh();
      await this.getTipoServicioPlaca();
      

      if (this.$route.params.accion == "Editar") {
        let formEdit = await this.$route.params.data_edit;
        this.form.placa = formEdit.placa;
        this.form.id = formEdit.id;
        this.form.estado = formEdit.estado;
        this.estado_anterior = formEdit.estado;
        this.form.email = formEdit.email;
        this.form.fecha_ingreso = formEdit.fecha_ingreso;
        this.form.fecha_salida = formEdit.fecha_salida;
        this.form.observaciones = formEdit.observaciones;
        this.form.tipo_servicio_placa = formEdit.tipo_servicio_placa;
        this.form.nEstado = formEdit.Estado;
        this.user_id = formEdit.user_id;
        this.form.placa = formEdit.placa;
        this.form.id = formEdit.id;
        this.form.estado = formEdit.estado;
        this.form.email = formEdit.email;
        this.form.fecha_ingreso = formEdit.fecha_ingreso;
        this.form.fecha_salida = formEdit.fecha_salida;
        this.form.observaciones = formEdit.observaciones;
        this.form.tipo_servicio_placa = formEdit.tipo_servicio_placa;

        if (formEdit.link_foto) {
          this.miniLogo = this.uri_docs + formEdit.link_foto;
        }
        this.fileFotografia = formEdit.link_foto ? formEdit.link_foto : null;
        if (formEdit.empresa) {
          this.form.empresa_id = await formEdit.empresa;

          let empresa = this.listsForms.empresas.find(
            (e) => e.numeracion === formEdit.empresa
          );
          this.form.empresa = empresa;
        }
        if (formEdit.activo) {
          this.form.activo_id = formEdit.activo;
          let activo = this.listsForms.activos.find(
            (a) => a.numeracion === formEdit.activo
          );
          this.form.activo = activo;
        }
        if (formEdit.area) {
          this.form.area_id = formEdit.area;
          let area = this.listsForms.areas.find(
            (a) => a.numeracion === formEdit.area
          );
          this.form.area = area;
        }
        if (formEdit.sitio_id) {
          this.form.sitio_id = formEdit.sitio_id;
          let sit = this.listsForms.sitios.find(
            (s) => s.id === formEdit.sitio_id
          );
          this.form.sitio = sit;
        }
        if (formEdit.operacion_vh) {
          this.form.operacion_vehiculo_id = formEdit.operacion_vh;
          let operacion = this.listsForms.operacion_vehiculos.find(
            (o) => o.numeracion === formEdit.operacion_vh
          );
          this.form.operacion_vehiculo = operacion;
        }
        if (formEdit.marca) {
          this.form.marca_id = formEdit.marca;
          let marca = this.listsForms.marcas.find(
            (m) => m.numeracion === formEdit.marca
          );
          this.form.marca = marca;
        }
        if (formEdit.modelo) {
          this.form.modelo_id = formEdit.modelo;
          let modelo = this.listsForms.modelos.find(
            (m) => m.modelo === formEdit.modelo
          );
          this.form.modelo = modelo;
        }
        if (formEdit.clase_vehiculo) {
          this.form.clase_vehiculo_id = formEdit.clase_vehiculo;
          let clase = this.listsForms.clases_vehiculos.find(
            (c) => c.numeracion === formEdit.clase_vehiculo
          );
          this.form.clase_vehiculo = clase;
        }
        this.formAnterior = {
          vehiculo_id: this.form.vehiculo_id,
          placa: this.form.placa,
          email: this.form.email,
          area_id: this.form.area_id,
          sitio_id: this.form.sitio_id,
          operacion_vehiculo_id: this.form.operacion_vehiculo_id,
          empresa_id: this.form.empresa_id,
          activo_id: this.form.activo_id,
          fecha_ingreso: this.form.fecha_ingreso,
          fecha_salida: this.form.fecha_salida,
          marca_id: this.form.marca_id,
          modelo_id: this.form.modelo_id,
          clase_vehiculo_id: this.form.clase_vehiculo_id,
          tipo_servicio_placa: this.form.tipo_servicio_placa,
        };
        this.fileFotografiaAnterior = this.fileFotografia;
        await this.getVehiculo(this.form.placa);
        this.form.vehiculo_id = this.vehiculo.id ? this.vehiculo.id : null;
        await this.$refs.SolicitudVehiculoDocumento.getVehiculo(this.form.id);
      } else {
        this.accion = "Crear";
        this.user_id = this.$store.state.user.id;
        if (this.$store.state.user.empresas.length > 0) {
          let emp_id = this.$store.state.user.empresas[0].pivot
            ? this.$store.state.user.empresas[0].pivot.empresa_id
            : null;
          let empresa =
            emp_id != null
              ? this.listsForms.empresas.find((e) => e.cod_alterno == emp_id)
              : null;
          this.form.empresa = empresa;
        }
      }
      if (this.form.estado && this.form.estado == 8) {
        this.bloquear = true;
      }
    },

    setBloquear() {
      if (this.form.estado) {
        if (this.form.estado > 1) {
          this.bloquear = true;
        }
      }
    },

    async getVehiculo(placa) {
      await axios
        .get(
          "api/funcionariosFrontera/HseSolicitudesVehiculos/vehiculo/" + placa
        )
        .then((response) => {
          this.vehiculo = response.data;
        });
    },

    async getUltimaSolicitud(placa) {
      await axios
        .get(
          "api/funcionariosFrontera/HseSolicitudesVehiculos/ultimaSolicitud/" +
            placa
        )
        .then((response) => {
          this.solicitudVh = response.data;
        });
    },

    async getSitios() {
      await axios
        .get("/api/funcionariosFrontera/listaSitios")
        .then((response) => {
          this.listsForms.sitios = response.data;
        });
    },

    async getTipoVh() {
      await axios
        .get("api/funcionariosFrontera/HseSolicitudesVehiculos/listaTipoVh")
        .then((response) => {
          this.listsForms.tipos_vehiculos = response.data;
        });
    },

    async getEmpresas() {
      await axios.get("/api/lista/164").then((response) => {
        this.listsForms.empresas = response.data;
      });
    },

    async getActivos() {
      await axios.get("/api/lista/117").then((response) => {
        this.listsForms.activos = response.data;
      });
    },

    async getClasesVehiculos() {
      await axios.get("/api/lista/163").then((response) => {
        this.listsForms.clases_vehiculos = response.data;
      });
    },

    async getOperacionVehiculos() {
      await axios.get("/api/lista/167").then((response) => {
        this.listsForms.operacion_vehiculos = response.data;
      });
    },

    async getMarcas() {
      await axios.get("/api/lista/12").then((response) => {
        this.listsForms.marcas = response.data;
      });
    },

    async getAreas() {
      await axios.get("/api/lista/99").then((response) => {
        this.listsForms.areas = response.data;
      });
    },

    async getTipoServicioPlaca() {
      await axios.get("/api/lista/181").then((response) => {
        this.listsForms.tipo_servicio_placa = response.data;
      });
    },

    validaFechaHoy() {
      if (this.form.fecha_ingreso) {
        let fecha_hoy = moment(this.hoy);
        let fecha_solicitud = moment(this.form.fecha_ingreso);
        if (fecha_solicitud.diff(fecha_hoy, "days") < 1) {
          this.form.fecha_ingreso = null;
          this.$swal({
            icon: "error",
            title:
              "Las Solicitudes Normales deben hacerce con más de un día de antelación.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    async save(accion) {
      let me = this;
      me.cargando = true;
      let formData = new FormData();
      formData.append("accionSave", me.accion);
      formData.append("id", me.form.id);
      formData.append(
        "vehiculo_id",
        me.form.vehiculo_id ? me.form.vehiculo_id : ""
      );
      formData.append("placa", me.form.placa);
      formData.append("email", me.form.email);
      formData.append("area", me.form.area_id);
      formData.append("activo", me.form.activo_id);
      formData.append("sitio_id", me.form.sitio_id);
      formData.append("operacion_vh", me.form.operacion_vehiculo_id);
      formData.append("empresa", me.form.empresa_id);
      formData.append("fecha_ingreso", me.form.fecha_ingreso);
      formData.append("fecha_salida", me.form.fecha_salida);
      formData.append("marca", me.form.marca_id);
      formData.append("modelo", me.form.modelo_id);
      formData.append("clase_vehiculo", me.form.clase_vehiculo_id);
      formData.append("observaciones", me.form.observaciones);
      formData.append("link_fotografia", me.fileFotografia);
      formData.append("tipo_servicio_placa", me.form.tipo_servicio_placa);
      formData.append("user_id", me.user_id);
      formData.append("accion", accion);
      formData.append(
        "observaciones_estado",
        me.observaciones_estado
          ? me.observaciones_estado
          : "Actualización automática del sistema por creación o ajustes de la solicitud inicial."
      );
      if (me.form.id) {
        await me.compararFormularios(me.formAnterior, me.form);
        if (me.estado_anterior == 5 || me.estado_anterior == 3) {
          formData.append("estado", 6);
        } else {
          formData.append("estado", me.form.estado);
        }
        formData.append("estado_anterior", me.estado_anterior);
        formData.append("accion", accion);
        await axios
          .post("api/funcionariosFrontera/HseSolicitudesVehiculos", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(async (response) => {
            me.form.id = response.data.id;
            me.form.estado = response.data.estado;
            me.form.nEstado = response.data.nEstado;
            me.estado_anterior = response.data.estado;
            me.formAnterior = {
              vehiculo_id: response.data.vehiculo_id,
              placa: response.data.placa,
              email: response.data.email,
              area_id: response.data.area,
              sitio_id: response.data.sitio_id,
              operacion_vehiculo_id: response.data.operacion_vh,
              empresa_id: response.data.empresa,
              activo_id: response.data.activo,
              fecha_ingreso: response.data.fecha_ingreso,
              fecha_salida: response.data.fecha_salida,
              marca_id: response.data.marca,
              modelo_id: response.data.modelo,
              clase_vehiculo_id: response.data.clase_vehiculo,
              tipo_servicio_placa: response.data.tipo_servicio_placa,
            };
            me.fileFotografiaAnterior = response.data.link_foto;
            me.$swal({
              icon: "success",
              title: "Los datos se guardaron exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            await me.$refs.SolicitudVehiculoDocumento.getVehiculo(
              response.data.id
            );
            await me.setBloquear();
            me.cargando = false;
          })
          .catch(function(error) {
            me.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            me.cargando = false;
          });
      } else {
        formData.append("estado", 1);
        await axios
          .post("api/funcionariosFrontera/HseSolicitudesVehiculos", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(async (response) => {
            me.form.id = await response.data.id;
            me.form.estado = await response.data.estado;
            me.estado_anterior = await response.data.estado;
            me.accion = "Editar";
            me.formAnterior = {
              vehiculo_id: await response.data.vehiculo_id,
              placa: await response.data.placa,
              email: await response.data.email,
              area_id: await response.data.area,
              sitio_id: await response.data.sitio_id,
              operacion_vehiculo_id: await response.data.operacion_vh,
              empresa_id: await response.data.empresa,
              activo_id: await response.data.activo,
              fecha_ingreso: await response.data.fecha_ingreso,
              fecha_salida: await response.data.fecha_salida,
              marca_id: await response.data.marca,
              modelo_id: await response.data.modelo,
              clase_vehiculo_id: await response.data.clase_vehiculo,
              tipo_servicio_placa: await response.data.tipo_servicio_placa,
            };
            me.fileFotografiaAnterior = await response.data.link_foto;
            await me.$refs.SolicitudVehiculoDocumento.getVehiculo(
              response.data.id
            );
            let error = response.data.msg;
            let msg = "";
            let icon = "";
            if (error) {
              msg = error;
              icon = "error";
            } else {
              msg = "Los datos se guardaron exitosamente...";
              icon = "success";
            }

            me.$swal({
              icon: icon,
              title: msg,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            error ? this.back() : "";
            me.cargando = false;
            me.setBloquear();
          })
          .catch(function(error) {
            me.cargando = false;
            me.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    changeEstado(observaciones_estado) {
      let datasol = {
        solicitud_id: this.form.id,
        estado_nuevo: 1,
        estado_anterior: this.form.estado,
        observaciones_estado,
      };
      if (this.form.estado > 1) {
        axios
          .post(
            "/api/funcionariosFrontera/HseSolicitudesVehiculos/changeEstado",
            datasol
          )
          .then((response) => {
            let data = response.data;
            this.form.estado = data.estado;
            this.form.nEstado = data.nEstado;
          });
      }
    },

    selectEmpresa() {
      this.form.empresa_id = null;
      if (this.form.empresa) {
        this.form.empresa_id = this.form.empresa.numeracion;
      }
    },

    selectActivo() {
      this.form.activo_id = null;
      if (this.form.activo) {
        this.form.activo_id = this.form.activo.numeracion;
      }
    },

    selectOperacionVh() {
      this.form.operacion_vehiculo_id = null;
      if (this.form.operacion_vehiculo) {
        this.form.operacion_vehiculo_id = this.form.operacion_vehiculo.numeracion;
      }
    },

    selectClaseVehiculo() {
      this.form.clase_vehiculo_id = null;
      if (this.form.clase_vehiculo) {
        this.form.clase_vehiculo_id = this.form.clase_vehiculo.numeracion;
      }
    },

    selectSitio() {
      this.form.sitio_id = null;
      if (this.form.sitio) {
        this.form.sitio_id = this.form.sitio.id;
      }
    },

    selectMarca() {
      this.form.marca_id = null;
      if (this.form.marca) {
        this.form.marca_id = this.form.marca.numeracion;
      }
    },

    selectArea() {
      this.form.area_id = null;
      if (this.form.area) {
        this.form.area_id = this.form.area.numeracion;
      }
    },

    selectModelo() {
      this.form.modelo_id = null;
      if (this.form.modelo) {
        this.form.modelo_id = this.form.modelo.modelo;
      }
    },

    async datosVehiculo(placa) {
      this.cargando = true;
      await this.getVehiculo(placa);
      await this.getUltimaSolicitud(placa);
      if (this.solicitudVh.placa) {
        this.$swal({
          title: "Atención!",
          text: `
            El vehículo con placa ${this.solicitudVh.placa} ya se encuentra
            registrado con la solicitud ${this.solicitudVh.id}
            ${
              this.solicitudVh.user
                ? " por el usuario " + this.solicitudVh.user.name
                : ""
            }
            de la empresa ${this.solicitudVh.nEmpresa}.`,
          icon: "warning",
          confirmButtonText: "Entendido!",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.value) {
            this.back();
          }
        });
      } else if (this.vehiculo.placa) {
        this.form.vehiculo_id = this.vehiculo.id ? this.vehiculo.id : null;
        this.form.placa = this.vehiculo.placa;
        this.form.marca = this.vehiculo.marca;
        this.miniLogo = null;
        this.fileFotografia = null;

        if (this.vehiculo.link_fotografia) {
          this.miniLogo = this.uri_docs + this.vehiculo.link_fotografia;
          this.fileFotografia = this.vehiculo.link_fotografia;
        }

        if (this.vehiculo.modelo) {
          const modelo = this.listsForms.modelos.find(
            (model) => model.modelo == this.vehiculo.modelo
          );
          this.form.modelo = modelo;
          this.selectModelo();
        }

        if (this.vehiculo.marca) {
          const marca = this.listsForms.marcas.find(
            (marcas) => marcas.numeracion === this.vehiculo.marca
          );
          this.form.marca = marca;
          this.selectMarca();
        }
      } else {
        this.miniLogo = null;
        this.fileFotografia = null;
        this.form = {
          id: null,
          placa: await placa,
          email: null,
          area: {},
          area_id: null,
          sitio: {},
          sitio_id: null,
          operacion_vehiculo: {},
          operacion_vehiculo_id: null,
          empresa: {},
          empresa_id: null,
          activo: {},
          activo_id: null,
          fecha_ingreso: null,
          fecha_salida: null,
          marca: {},
          marca_id: null,
          modelo: {},
          modelo_id: null,
          clase_vehiculo: {},
          clase_vehiculo_id: null,
          observaciones: "",
        };
      }
      this.cargando = false;
    },

    SoloLetras(letra) {
      let tecla = document.all ? letra.keyCode : letra.which;

      //Tecla de retroceso para borrar, y espacio siempre la permitedescripcion
      if (tecla == 8 || tecla == 32) {
        return true;
      }

      // Patrón de entrada
      let patron = /[A-Za-z]/;
      let tecla_final = String.fromCharCode(tecla);
      return patron.test(tecla_final);
    },

    // Funciones para carga de imagenes
    obtenerImagen(e) {
      this.miniLogo = null;

      this.archivoValido = false;
      if (e.target.files[0]) {
        this.cargando = true;
        const file = e.target.files[0];
        if (this.validaArchivo(file)) {
          this.archivoValido = true;
          this.fileFotografia = file;

          this.cargarImagen(file);
        }
      }
    },

    validaArchivo(file) {
      let flag = true;
      if (file.size > 2048000) {
        flag = false;
        this.fileFotografia = null;
        this.cargando = false;
        this.$swal({
          icon: "error",
          title: `El Archivo es muy pesado para subirlo`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return flag;
    },

    cargarImagen(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.miniLogo = e.target.result;
      };
      reader.readAsDataURL(file);
      this.cargando = false;
    },

    getModelos() {
      let anioActual = moment().format("YYYY");
      anioActual = parseInt(anioActual) + 1;
      for (let i = 1970; i <= anioActual; i++) {
        this.listsForms.modelos.push({
          modelo: i,
        });
      }
    },

    destroyImage() {
      this.miniLogo = null;
      this.fileFotografia = null;
    },

    validaFechas() {
      if (this.form.fecha_ingreso && this.form.fecha_salida) {
        const fecha_menor = new Date(this.form.fecha_ingreso);
        const fecha_mayor = new Date(this.form.fecha_salida);
        //Diferencia de días entre las fechas de ingreso y salida
        const diferenciaEnMs = fecha_mayor - fecha_menor;
        const diferenciaEnDias = Math.floor(
          diferenciaEnMs / (1000 * 60 * 60 * 24)
        );
        // Se valida la diferencia de los dias que no sea mayor a 3
        if (
          diferenciaEnDias >= 3 &&
          this.form.operacion_vehiculo.numeracion == 2
        ) {
          this.form.fecha_salida = null;
          this.$swal({
            icon: "error",
            title: `La fecha de vencimiento no puede ser mayor a 3 días de la fecha de emisión...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.fecha_salida = null;
          this.$swal({
            icon: "error",
            title: `La fecha de emisión no puede ser mayor a la fecha de vencimiento...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    back() {
      return this.$router.replace("/FuncionariosFrontera/SolicitudesVehiculos");
    },

    solicitar() {
      this.observaciones_estado = "";
      let expired_files = this.$refs.SolicitudVehiculoDocumento.validarFechasSolicitar();

      if (expired_files.length > 0) {
        this.form.estado = 3;
        let description_files = expired_files
          .map((file, index, arr) => {
            if (index === 0) {
              return "( " + file;
            } else if (index === arr.length - 1) {
              return " y " + file;
            } else {
              return ", " + file;
            }
          })
          .join("");

        this.observaciones_estado =
          "Rechazado por Documentos vencidos " + description_files + " )";

        this.save(3);
      } else {
        this.form.estado = 6;
        this.save(2);
      }
    },

    async validarHorario() {
      let horaAct = moment().format("HH");
      if (horaAct >= 4 && horaAct < 19) {
        await this.init();
      } else {
        this.$swal({
          title: "Horario de atención:",
          text: "4:00AM - 7:00 PM",
          icon: "warning",
          confirmButtonText: "Ok!",
        }).then((result) => {
          this.back();
        });
      }
    },

    alert() {
      this.$swal({
        icon: "warning",
        title: `Si desea cambiar el paquete de documentos debe GUARDAR...`,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    },

    async compararFormularios(form1, form2) {
      for (let key in form1) {
        if (form1[key] !== form2[key]) {
          this.form.estado = 1;
        }
      }
      if (this.fileFotografiaAnterior !== this.fileFotografia) {
        this.form.estado = 1;
      }
    },
  },

  computed: {
    isValidEmail() {
      return /^[^@]+@\w+(\.\w+)+\w$/.test(this.form.email);
    },

    isValidPlaca() {
      return /^(?!\s)[a-z A-Z 0-9 Ññ]\S*$/.test(this.form.placa);
    },

    logo() {
      return this.miniLogo;
    },
  },

  async mounted() {
    this.cargando = true;
    //await this.validarHorario();
    await this.init();
    this.cargando = false;
  },
};
</script>

<style>
.button-container {
  display: inline-block;
  position: relative;
}

.button-container a {
  bottom: 3em;
  right: 4em;
  color: white;
  text-transform: uppercase;
  padding: 1em 1.8em;
  opacity: 0.3;
  color: #fff !important;
  background: #ff0000;
  position: absolute;
  outline: 2px solid;
  outline-color: rgb(248, 8, 8);
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}

.button-container a:hover {
  box-shadow: inset 0 0 20px rgb(248, 8, 8), 0 0 20px rgb(248, 8, 8);
  outline-color: rgba(49, 138, 172, 0);
  outline-offset: 80px;
  text-shadow: 3px 3px 3px #fff;
}

.login-block {
  background: #001871; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom right,
    import #201747,
    #001871
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom right,
    #201747,
    #001871
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  float: left;
  width: 100%;
  padding: 50px 0;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

.well {
  height: 100%;
  min-height: 20px;
  padding: 14px;
  margin: auto;
  margin-bottom: 10px;
  width: 100%;
  border: 2px solid #09cce6;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.well-legend {
  display: block;
  font-size: 20px;
  width: auto;
  min-width: 300px;
  padding: 2px 7px 2px 5px;
  line-height: unset;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.ir-arriba {
  display: none;
  background-repeat: no-repeat;
  font-size: 20px;
  color: black;
  cursor: pointer;
  position: fixed;
  bottom: 80px;
  right: 8px;
  z-index: 2;
}
</style>
