var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},_vm._l((_vm.vehiculo.paquetes),function(paquete){return _c('div',{key:paquete.id,staticClass:"col-md-12"},[_c('div',{staticClass:"card card-warning card-outline"},[_c('div',{staticClass:"card-header pt-2 pb-2"},[_c('h3',{staticClass:"card-title"},[_c('i',{staticClass:"far fa-folder"}),_vm._v(" "+_vm._s(paquete.nombre)+" ")]),_vm._m(0,true)]),_c('div',{staticClass:"card-body",staticStyle:{"display":"block"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('table',{staticClass:"table table-bordered table-hover table-sm text-center",attrs:{"id":"docs"}},[_c('thead',[_c('tr',[_c('th',[_vm._v("Documento")]),_c('th',[_vm._v("Referencia")]),_c('th',[_vm._v("Emisor")]),_c('th',[_vm._v("Fecha Emisión")]),_c('th',[_vm._v("Fecha Vencimiento")]),(!_vm.$parent.modalDetalle.id)?_c('th',{attrs:{"colspan":"3"}},[_vm._v(" Opciones ")]):_vm._e()])]),_c('tbody',_vm._l((paquete.documentos),function(documento){return _c('tr',{key:documento.id,class:documento.pivot.relevante &&
                        !_vm.validaDoc(_vm.vehiculo.documentos_files, documento, 2)
                          ? 'alert-default-danger'
                          : 'alert-default-success'},[_c('td',{domProps:{"textContent":_vm._s(documento.descripcion)}}),_c('td',[(
                            _vm.validaDoc(_vm.vehiculo.documentos_files, documento, 1)
                          )?_c('p',[_vm._v(" "+_vm._s(_vm.vehiculo.documentos_files.find( (file) => file.documento_id === documento.id ).referencia)+" ")]):_vm._e()]),_c('td',[(
                            _vm.validaDoc(_vm.vehiculo.documentos_files, documento, 1)
                          )?_c('p',[_vm._v(" "+_vm._s(_vm.vehiculo.documentos_files.find( (file) => file.documento_id === documento.id ).emisor)+" ")]):_vm._e()]),_c('td',[(
                            _vm.validaDoc(_vm.vehiculo.documentos_files, documento, 1)
                          )?_c('p',[_vm._v(" "+_vm._s(_vm.vehiculo.documentos_files.find( (file) => file.documento_id === documento.id ).fecha_emision)+" ")]):_vm._e()]),_c('td',[(
                            _vm.validaDoc(_vm.vehiculo.documentos_files, documento, 1)
                          )?_c('p',[_vm._v(" "+_vm._s(_vm.vehiculo.documentos_files.find( (file) => file.documento_id === documento.id ).fecha_vencimiento)+" ")]):_vm._e()]),(!_vm.$parent.modalDetalle.id)?_c('td',[_c('button',{staticClass:"btn btn-info btn-sm",attrs:{"type":"button","disabled":_vm.validaDoc(
                              _vm.vehiculo.documentos_files,
                              documento,
                              2
                            ) || _vm.$parent.bloquear,"data-toggle":"modal","data-target":"#Modal_docs"},on:{"click":function($event){return _vm.abrirModalDoc('Agregar', documento)}}},[_c('i',{staticClass:"fa fa-upload"})])]):_vm._e(),(!_vm.$parent.modalDetalle.id)?_c('td',[(
                            _vm.validaDoc(_vm.vehiculo.documentos_files, documento, 2)
                          )?_c('a',{staticClass:"btn btn-primary btn-sm",attrs:{"target":"_blank","href":_vm.uri_docs +
                              _vm.vehiculo.documentos_files.find(
                                (file) => file.documento_id === documento.id
                              ).link}},[_c('i',{staticClass:"fa fa-download"})]):_vm._e()]):_vm._e(),(!_vm.$parent.modalDetalle.id)?_c('td',[(
                            _vm.validaDoc(_vm.vehiculo.documentos_files, documento, 1)
                          )?_c('button',{staticClass:"btn btn-danger btn-sm",attrs:{"disabled":_vm.$parent.bloquear,"type":"button"},on:{"click":function($event){_vm.eliminarDoc(
                              _vm.vehiculo.documentos_files.find(
                                (file) => file.documento_id === documento.id
                              ).id
                            )}}},[_c('i',{staticClass:"fas fa-trash-alt"})]):_vm._e()]):_vm._e()])}),0)])])])])])])}),0)]),_c('div',{staticClass:"modal fade",attrs:{"id":"Modal_docs"}},[_c('div',{staticClass:"modal-dialog modal-md"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-frontera-top-left pt-2 pb-2"},[_c('h4',{staticClass:"modal-title text-white"},[_vm._v(_vm._s(_vm.modal.title))]),_c('button',{ref:"closeModal",staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close","id":"close-modal"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"descripcion"}},[_vm._v("Descripción")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formDoc.descripcion),expression:"formDoc.descripcion"}],staticClass:"form-control form-control-sm",class:_vm.$v.formDoc.descripcion.$invalid
                      ? 'is-invalid'
                      : 'is-valid',attrs:{"type":"text","id":"descripcion","placeholder":"descripcion","readonly":""},domProps:{"value":(_vm.formDoc.descripcion)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formDoc, "descripcion", $event.target.value)}}})])]),_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"referencia"}},[_vm._v("Referencia")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formDoc.referencia),expression:"formDoc.referencia"}],staticClass:"form-control form-control-sm",class:_vm.$v.formDoc.referencia.$invalid ? 'is-invalid' : 'is-valid',attrs:{"type":"text","id":"referencia","placeholder":"Referencia"},domProps:{"value":(_vm.formDoc.referencia)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formDoc, "referencia", $event.target.value)}}})])]),_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"emisor"}},[_vm._v("Emisor")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formDoc.emisor),expression:"formDoc.emisor"}],staticClass:"form-control form-control-sm",class:_vm.$v.formDoc.emisor.$invalid ? 'is-invalid' : 'is-valid',attrs:{"type":"text","id":"emisor","placeholder":"Emisor"},domProps:{"value":(_vm.formDoc.emisor)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formDoc, "emisor", $event.target.value)}}})])]),_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"fecha_emision"}},[_vm._v("Fecha Emisión")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formDoc.fecha_emision),expression:"formDoc.fecha_emision"}],staticClass:"form-control form-control-sm",class:_vm.$v.formDoc.fecha_emision.$invalid
                      ? 'is-invalid'
                      : 'is-valid',attrs:{"type":"date","id":"fecha_emision","placeholder":"Fecha Emisión"},domProps:{"value":(_vm.formDoc.fecha_emision)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.formDoc, "fecha_emision", $event.target.value)},_vm.validaFechas]}})])]),(_vm.formDoc.vencimiento)?_c('div',{staticClass:"col-lg-6 col-md-6 col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"fecha_vencimiento"}},[_vm._v("Fecha Vencimiento")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formDoc.fecha_vencimiento),expression:"formDoc.fecha_vencimiento"}],staticClass:"form-control form-control-sm",class:_vm.$v.formDoc.fecha_vencimiento.$invalid
                      ? 'is-invalid'
                      : 'is-valid',attrs:{"type":"date","id":"fecha_vencimiento","placeholder":"Fecha Emisión"},domProps:{"value":(_vm.formDoc.fecha_vencimiento)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.formDoc, "fecha_vencimiento", $event.target.value)},_vm.validaFechas]}})])]):_vm._e()]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('input',{ref:"file",staticClass:"form-control-file",attrs:{"type":"file","id":"btnFile","accept":"application/msword,application/pdf,application/rtf,image/*"},on:{"change":_vm.getFile}})])])]),_c('div',{staticClass:"modal-footer justify-content-between"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.saveDoc()}}},[_vm._v(" Guardar ")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-tools"},[_c('button',{staticClass:"btn btn-tool",attrs:{"type":"button","data-card-widget":"collapse","data-toggle":"tooltip","title":"Collapse"}},[_c('i',{staticClass:"fas fa-minus"})])])
}]

export { render, staticRenderFns }